<template>
  <b-card>

    <!-- form -->
    <validation-observer ref="blockListForm">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-overlay
              :show="isLoading"
              rounded
              opacity="0.5"
              spinner-small
              spinner-variant="primary"
            >
              <div class="section-block mb-4">
                <div class="section-block__title">
                  <feather-icon
                    icon="LockIcon"
                    width="24"
                    height="24"
                  />
                  <h2>Block Access Point</h2>
                </div>
                <!-- <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="Name:"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    vid="name"
                    rules="required|max:255"
                  >

                    <div class="neighbouring-input-w-icon">
                      <b-form-input
                        v-model="form.name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Name"
                        autocomplete="off"
                      />
                      <div class="invisible-icon" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div> -->

                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="MAC Address:"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="MAC Address"
                      vid="mac_address"
                      rules="required|max:255"
                    >
                      <b-form-input
                        v-model="form.mac_address"
                        :state="errors.length > 0 ? false:null"
                        placeholder="MAC Address:"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Distributor"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Action"
                      vid="action"
                      rules="required"
                    >
                      <v-select
                        v-model="action"
                        :options="actionsList"
                        placeholder="Select block type"
                        label="value"
                        :searchable="false"
                        :clearable="false"
                        class=" w-100"
                        @input="selectBlockAction($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div
                  v-if="selectedAction === 'time_block'"
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Start Time"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Start Time"
                      vid="time_start"
                      rules="required"
                    >
                      <div class="neighbouring-form-date w-100">
                        <VueTimepicker
                          v-model="form.time_start"
                          format="HH:mm:ss"
                          :second-interval="5"
                        />
                      </div>
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="End Time"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="endTime"
                      vid="time_end"
                      rules="required"
                    >
                      <div class="neighbouring-form-date  w-100">
                        <VueTimepicker
                          v-model="form.time_end"
                          format="HH:mm:ss"
                          :second-interval="5"
                        />
                      </div>
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <b-form-group
                  v-if="selectedAction === 'time_block'"
                  label="Start Time"
                  label-class="label-class"
                  class="neighbouring-form-group mt-1 mb-0"
                />
                <div
                  v-if="selectedAction === 'time_block'"
                  class="children-flex-list"
                >
                  <div
                    class="d-flex align-items-center justify-content-between "
                  >

                    <b-form-checkbox
                      v-model="isAllSelected"
                      inline
                      class="mr-0"
                      @change="handleChangeAll"
                    />
                    <p class="mb-0 mr-2">
                      All
                    </p>
                  </div>
                  <div
                    v-for="(item, index) in daysSelection"
                    :key="index"
                    class="d-flex align-items-center justify-content-between "
                    style="width:70px;"
                  >
                    <b-form-checkbox
                      v-model="item.selected"
                      class="mr-0"
                      inline
                      @change="checkSelected"
                    />
                    <p class="mb-0 mr-2">
                      {{ item.title }}
                    </p>
                  </div>
                </div>
              </div>
            </b-overlay>

            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right buttons-container__left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                ok-variant="secondary"
                @click.prevent="goBack"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click.prevent="validationForm"
              >
                Update
              </b-button>
            </b-col>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BCard, BFormCheckbox, BButton, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import { required, max } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import axios from '../../../axios-resolver'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BButton,
    BOverlay,
    ValidationProvider,
    BFormCheckbox,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    VueTimepicker,
    BRow,
    vSelect,
    BCol,
  },
  data() {
    return {
      siteId: this.$route.params.id,
      blockedWifiId: this.$route.params.blockedWifiId,
      form: {
        mac_address: '',
        time_start: '00:00:00',
        time_end: '00:00:00',
        days: [],
        disabled: false,
        // name: '',
      },
      daysSelection: [],
      isEditForm: false,
      actionsList: [
        {
          type: 'block',
          value: 'Block',
        },
        {
          type: 'time_block',
          value: 'Time Block',
        },
      ],
      selectedAction: null,
      action: null,
      required,
      max,
      isAllSelected: true,
      isLoading: true,
    }
  },
  watch: {
    action(val) {
      if (val?.type) {
        this.selectedAction = val.type
      }
    },
  },
  created() {
    this.createDays()
    this.getDeviceInfo()
  },
  methods: {
    ...mapActions('blockList', [
      'updateItem',
    ]),
    async getDeviceInfo() {
      try {
        const { data: { data } } = await axios.get(`/hardware/control/${this.siteId}/caps-man/access-list/${this.blockedWifiId}`)
        this.form = {
          ...data,
        }
        const isDatePicked = data.time_end !== '00:00:00' || data.time_start !== '00:00:00'
        const isSelectedDate = data.days.some(item => item.enable === true) || isDatePicked
        this.selectedAction = isSelectedDate ? 'time_block' : 'block'
        this.action = this.actionsList.find(item => item.type === this.selectedAction)
        if (isSelectedDate) {
          this.daysSelection = this.daysSelection.map(day => {
            const found = data.days.find(item => item.title === day.title)
            return {
              ...day,
              selected: found ? found.enable : true,
            }
          })
        }
        this.isAllSelected = this.daysSelection.every(item => item.selected)
        this.isLoading = false
      } catch (error) {
        console.warn(error)
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    checkSelected() {
      if (this.daysSelection.every(item => item.selected)) {
        this.isAllSelected = true
      } else {
        this.isAllSelected = false
      }
    },
    handleChangeAll() {
      if (this.isAllSelected) {
        this.daysSelection = this.daysSelection.map(item => ({
          ...item,
          selected: true,
        }))
      } else {
        this.daysSelection = this.daysSelection.map(item => ({
          ...item,
          selected: false,
        }))
      }
    },
    onSearchNetwork(search) {
      this.searchNetwork = search
    },
    async validationForm() {
      const valid = await this.$refs.blockListForm.validate()

      if (valid) {
        try {
          const payload = this.selectedAction === 'time_block' ? {
            ...this.form,
            days: this.daysSelection.filter(item => item.selected).map(item => item.id),
          } : {
            disabled: false,
            mac_address: this.form.mac_address,
            name: this.form.name,
          }
          await this.updateItem({ id: this.$route.params.id, payload })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.goBack()
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.blockListForm.setErrors(fields)
          } else {
            const { message } = error.response.data

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                variant: 'danger',
                message,
              },
            })
          }
        }
      }
    },
    createDays() {
      this.daysSelection = [
        {
          title: 'Mon',
          selected: true,
          id: 1,
        },
        {
          title: 'Tue',
          selected: true,
          id: 2,
        },
        {
          title: 'Wed',
          selected: true,
          id: 3,
        },
        {
          title: 'Thu',
          selected: true,
          id: 4,
        },
        {
          title: 'Fri',
          selected: true,
          id: 5,
        },
        {
          title: 'Sat',
          selected: true,
          id: 6,
        },
        {
          title: 'Sun',
          selected: true,
          id: 7,
        },
      ]
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
