import Vue from 'vue'

// axios
import axios from '@axios'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

// copy axios object

const axiosIns = { ...axios }

axiosIns.defaults.withCredentials = true

axiosIns.interceptors.response.use(
  response => response,
  error => {
    console.log('error', { ...error })
    const status = error.response ? error.response.status : null

    if (!status) {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: 'Server error',
          icon: 'BellIcon',
          variant: 'danger',
        },
      })
    } else {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: error.response.data.message,
          icon: 'BellIcon',
          variant: 'error',
        },
      })
    }

    throw error
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
